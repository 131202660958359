import { ActionType, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingSettings } from "../models";
import { receiveSettingsAction } from "./actions";

const initialState: LoadingSettings = {
  state: ReduxStoreState.Initial,
  settings: undefined,
  loading: false,
};

// eslint-disable-next-line import/prefer-default-export
export const settingsReducer = createReducer<
  LoadingSettings,
  ActionType<typeof receiveSettingsAction>
>(initialState)
  .handleAction(receiveSettingsAction.request, (state) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveSettingsAction.failure, (state) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(receiveSettingsAction.success, (_, action) => ({
    ...action.payload,
    loading: false,
    state: ReduxStoreState.Success,
  }));
