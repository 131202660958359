import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Option, Radio, Select } from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TenantEnum } from "../../api";
import {
  accountSelector,
  advertisersFromStoreSelector,
  createStructuredAppSelector,
  organisationsFromStoreSelector,
  settingsSelector,
} from "../../shared/selectors";
import { receiveAdvertisersAction } from "../../store/advertisers/actions";
import { ReduxStoreState } from "../../store/base";
import { receiveOrganisationsAction } from "../../store/organisations/actions";
import { klantportalAdverteerder } from "../../utils/constants";
import { isTenantSwitchingEnabled } from "../../utils/settingsHelper";
import { isAdmin, isInternalUser, isMedewerker } from "../../utils/userHelper";

export const tenantTranslations = {
  [TenantEnum.National]: t`Nationaal`,
  [TenantEnum.Regional]: t`Regionaal`,
};

interface OperationalContextStateFormProps {
  fieldName: string;
  autoFocus?: boolean;
  allowClear?: boolean;
  setLoadingState?: (loading: boolean) => void;
  setShowAdvertisers?: (value: boolean) => void;
}

const operationalContextFormSelectors = createStructuredAppSelector({
  organisationsFromStore: organisationsFromStoreSelector,
  advertisersFromStore: advertisersFromStoreSelector,
  account: accountSelector,
  settings: settingsSelector,
});

const OperationalContextStateForm = ({
  fieldName,
  autoFocus,
  allowClear,
  setLoadingState: setOperationalContextFormLoadingState,
  setShowAdvertisers,
}: OperationalContextStateFormProps) => {
  const form = AntForm.useFormInstance();
  const { i18n } = useLingui();
  const { account, organisationsFromStore, advertisersFromStore, settings } =
    useSelector(operationalContextFormSelectors);

  // Some variables
  const admin = isAdmin(account);
  const internalUser = isInternalUser(account);
  const medewerker = isMedewerker(account);
  const enableTenantSwitch = isTenantSwitchingEnabled(settings.settings);

  // Logic to set displayed organisations based on tenant
  const selectedTenantForm: TenantEnum = AntForm.useWatch([
    fieldName,
    "tenant",
  ]);
  const selectedTenant = enableTenantSwitch
    ? selectedTenantForm
    : TenantEnum.National;

  // Logic to display advertiser when needed
  const selectedOrgCode = AntForm.useWatch([fieldName, "organisationCode"]);
  const showAdvertiser = useMemo(
    () => selectedOrgCode === klantportalAdverteerder,
    [selectedOrgCode]
  );
  useEffect(
    () => setShowAdvertisers?.(showAdvertiser),
    [showAdvertiser, setShowAdvertisers]
  );

  // Loading state trigger
  const isLoading = useMemo(() => {
    const isLoadingOrganisations =
      organisationsFromStore.state === ReduxStoreState.Initial ||
      organisationsFromStore.state === ReduxStoreState.Loading;
    const isLoadingAdvertisers =
      advertisersFromStore.state === ReduxStoreState.Initial ||
      advertisersFromStore.state === ReduxStoreState.Loading;

    return isLoadingOrganisations || isLoadingAdvertisers;
  }, [organisationsFromStore.state, advertisersFromStore.state]);

  // Logic to reset selected organisation after tenant switch
  const resetOrganisationCode = useCallback(() => {
    form.setFieldValue([fieldName, "organisationCode"], undefined);
  }, [form, fieldName]);

  useEffect(() => {
    setOperationalContextFormLoadingState?.(isLoading);
  }, [setOperationalContextFormLoadingState, isLoading]);

  const userCanViewData = admin || (internalUser && medewerker);

  // Startup and update
  const dispatch = useDispatch();
  useEffect(() => {
    if (userCanViewData) {
      if (organisationsFromStore.state === ReduxStoreState.Initial) {
        dispatch(receiveOrganisationsAction.request());
      }
      if (advertisersFromStore.state === ReduxStoreState.Initial) {
        dispatch(receiveAdvertisersAction.request());
      }
    }
  }, [
    dispatch,
    userCanViewData,
    organisationsFromStore.state,
    advertisersFromStore.state,
  ]);

  // Output
  return (
    <>
      {enableTenantSwitch && (
        <Form.Item
          label={i18n._(t`Tenant`)}
          rules={[{ required: true, message: i18n._(t`Veld is verplicht`) }]}
          name={[fieldName, "tenant"]}
        >
          <Radio.Group
            onChange={resetOrganisationCode}
            options={[
              {
                label: i18n._(tenantTranslations[TenantEnum.National]),
                value: TenantEnum.National,
              },
              {
                label: i18n._(tenantTranslations[TenantEnum.Regional]),
                value: TenantEnum.Regional,
              },
            ]}
          />
        </Form.Item>
      )}

      {!isLoading && (
        <>
          <Form.Item
            name={[fieldName, "organisationCode"]}
            label={i18n._(t`Organisatie`)}
            rules={[{ required: true }]}
          >
            <Select.Search
              placeholder={i18n._(t`Zoek een organisatie`)}
              optionFilterProp="search"
              autoFocus={autoFocus}
              allowClear={allowClear}
              showAction={["focus"]}
            >
              {organisationsFromStore.organisations?.[selectedTenant]?.map(
                ({ code, name }) => (
                  <Option value={code} key={code} search={`${name} ${code}`}>
                    {name} ({code})
                  </Option>
                )
              )}
            </Select.Search>
          </Form.Item>
          {showAdvertiser ? (
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.orgcode !== currentValues.orgcode
              }
            >
              {() =>
                showAdvertiser ? (
                  <Form.Item
                    name={[fieldName, "advertiserCode"]}
                    label={i18n._(t`Adverteerder`)}
                    rules={[{ required: true }]}
                  >
                    <Select.Search
                      placeholder={i18n._(t`Zoek een adverteerder`)}
                      allowClear={allowClear}
                      optionFilterProp="search"
                    >
                      {advertisersFromStore.advertisers?.[selectedTenant]?.map(
                        ({ id, name }) => (
                          <Option value={id} key={id} search={`${name} ${id}`}>
                            {name}
                          </Option>
                        )
                      )}
                    </Select.Search>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          ) : null}
        </>
      )}
    </>
  );
};

export default OperationalContextStateForm;
